import React, {createContext, Dispatch, SetStateAction} from "react";
import VerzoekjeEntity from "./entity/VerzoekjeEntity";
import SettingsEntity from "./entity/SettingsEntity";
import EnvEntity from "./entity/EnvEntity";

interface AppContextProperties {
	loading: boolean,
	setLoading: Dispatch<SetStateAction<boolean>>,
	verzoekjes: VerzoekjeEntity[],
	admin: boolean,
	modal: any,
	setModal: Dispatch<SetStateAction<any>>,
	settings: SettingsEntity,
	refreshSettings: () => Promise<any>,
	refreshVerzoekjes: () => void,
	env: EnvEntity,
	getText: (key: string, fallback: string) => string,
	user: string
}

const AppContext = createContext({
	loading: false,
	setLoading: () => void 0,
	verzoekjes: [],
	admin: false,
	modal: void 0,
	setModal: () => void 0,
	settings: {},
	refreshSettings: () => Promise.resolve(null),
	refreshVerzoekjes: () => void 0,
	env: {} as EnvEntity,
	getText: (key: string, fallback: string) => '',
	user: ''
} as AppContextProperties);

export default AppContext;