import React, {useContext, useState} from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import {checkHttpStatus, getArtist, getTitle} from './utils';
import AppContext from "./AppContext";
import VerzoekjeEntity from "./entity/VerzoekjeEntity";
import Avatar from "./Avatar";

const Avatars = ({userKey, userKeys} : {userKey: string | undefined, userKeys: string[] | undefined}) => {
	const combinedUsers: string[] = [...new Set([userKey, ...(userKeys ?? [])])]
		.filter(x => !!x) as string[];

	return <span>{combinedUsers.map(userKey => <Avatar userKey={userKey} />)}</span>
}

const Verzoekje = ({ verzoekje, users }: { verzoekje: VerzoekjeEntity, users: string[] }) => {
	const {
		key,
		user,
		liedje
	} = verzoekje;
	const artist = getArtist(liedje);
	const title = getTitle(liedje);

	const [loading, setLoading] = useState<boolean>(false);

	const {
		admin,
		refreshVerzoekjes,
		env
	} = useContext(AppContext);

	return <ListGroup.Item className="liedje">
		{admin &&
			<Avatars userKey={user} userKeys={users} />}
		<span className="artist">{artist}</span> - <span className="title">{title}</span>
		{(() => {
			if (!admin) {
				return <span />;
			} else if (loading) {
				return <Button variant="danger" disabled>Verwijderen...</Button>;
			} else {
				return <Button variant="danger" onClick={() => {
					setLoading(true);
					fetch(`${env.BACKEND_ENDPOINT}/verzoek/${key}`,
						{
							method: 'DELETE',
							body: JSON.stringify({ key })
						})
						.then(checkHttpStatus)
						.then(refreshVerzoekjes)
						.catch(err => window.alert(`Er is een fout opgetreden.\n\n${err.message}`))
						.then(() => setLoading(false));
				}}>Verwijderen</Button>
			}
		})()}
	</ListGroup.Item>;
};

export default Verzoekje;