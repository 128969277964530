import React, {useEffect, useState} from 'react';
import EnvEntity from "./entity/EnvEntity";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import {httpCheckParse} from "./utils";
import App from "./App";

const EnvLoader = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [env, setEnv] = useState<EnvEntity | null>(null);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		if (env || loading || error) {
			return;
		}

		setLoading(true);
		fetch('/env.json')
			.then(httpCheckParse)
			.then(setEnv)
			.catch(setError)
			.then(() => setLoading(false));
	}, [env, loading, setLoading, setEnv, error, setError]);

	if (loading) {
		return <Container>
			<Row>
				<Col>
					<div style={{
						width: '100%',
						textAlign: 'center',
						padding: 20
					}}>
						<Spinner animation="border" style={{
							display: 'inline-block',
							width: 200,
							height: 200
						}} />
						<p>Configuratie ophalen...</p>
					</div>
				</Col>
			</Row>
		</Container>;
	} else if (error) {
		return <Container>
			<Row>
				<Col>
					<Alert variant="danger">Er is iets misgelopen bij het ophalen van de configuratie.</Alert>
					<Alert variant="warning">{error.message}</Alert>
				</Col>
			</Row>
		</Container>;
	} else if (!env) {
		return <Container>
			<Row>
				<Col>
					<Alert>Configuratie ontbreekt.</Alert>
				</Col>
			</Row>
		</Container>;
	}

	return <App env={env} />
};

export default EnvLoader;