import React, {useContext, useMemo, useState} from 'react';
import LiedjeEntity from './entity/LiedjeEntity';
import { Button, ListGroup } from 'react-bootstrap';
import { getArtist, getTitle, httpCheckParse } from './utils';
import AppContext from "./AppContext";
import VerzoekjeEntity from "./entity/VerzoekjeEntity";

const Liedje = ({ liedje, onVerzoekje, verzoekjeAllowed }: {
	liedje: LiedjeEntity,
	onVerzoekje: (verzoekjeKey: string) => void,
	verzoekjeAllowed: boolean
}) => {
	const {
		key,
	} = liedje;

	const {
		verzoekjes,
		refreshVerzoekjes,
		env,
		user
	} = useContext(AppContext);
	const requested = useMemo(() => verzoekjes.map(({ liedje: { key }}) => key)
		.includes(key),
	[verzoekjes]);
	const requestedByUser = useMemo(() => verzoekjes.filter((iVerzoekje : VerzoekjeEntity) => user === iVerzoekje.user)
		.map(({ liedje: { key }}) => key)
		.includes(key),
		[verzoekjes, user]);

	const [loading, setLoading] = useState<boolean>(false);

	const artist = getArtist(liedje);
	const title = getTitle(liedje);

	return <ListGroup.Item className="liedje">
		<span className="artist">{artist}</span> - <span className="title">{title}</span>
		{(() => {
			if (loading) {
				return <Button variant="secondary" disabled>Aanvragen...</Button>;
			} else if (requestedByUser) {
				return <Button variant="secondary" disabled>Reeds aangevraagd</Button>;
			} else if (!verzoekjeAllowed) {
				return <Button variant="secondary" disabled>Wacht nog even</Button>;
			} else {
				return <Button onClick={e => {
					setLoading(true);
					fetch(`${env.BACKEND_ENDPOINT}/verzoek`,
						{
							method: 'POST',
							body: JSON.stringify({ key }),
							headers: {
								'x-user': user
							}
						})
						.then(httpCheckParse)
						.then(data => onVerzoekje(data.key))
						.then(refreshVerzoekjes)
						.catch(err => window.alert(`Er is een fout opgetreden.\n\n${err.message}`))
						.then(() => setLoading(false));
				}}>Verzoek</Button>
			}
		})()}
	</ListGroup.Item>;
};

export default Liedje;