import LiedjeEntity from "./entity/LiedjeEntity";

const checkHttpStatus = (res: Response) => {
	if ([200, 201, 204].includes(res.status)) {
		return res;
	}

	throw Error(`${res.status} ${res.statusText}`);
};

const httpCheckParse = (res: any) : Promise<any | null> => {
	const isStatusOk = [200, 201, 204].includes(res.status);
	const fallbackErrorMessage = `${res.status} ${res.statusText}`;

	try {
		if (!res.body || res.status === 204) {
			return Promise.resolve(null);
		}

		return res.json()
			.then((data : any) => {
				if (isStatusOk) {
					return data;
				}

				throw new Error(data?.error || fallbackErrorMessage);
			});
	} catch (ex) {
		throw isStatusOk ? ex : new Error(fallbackErrorMessage);
	}
}

const checkNotEmpty = (str: string | null | undefined) => {
	if (!str) {
		throw new Error('Value is null');
	} else if (String(str).trim() === '') {
		throw new Error('Value is an empty string');
	}

	return str;
};

const getArtist = (liedje: LiedjeEntity) => {
	const file = liedje.file.split('.').slice(0, -1).join('.');
	const id3 = liedje?.artist ?? '';

	if (id3) {
		return id3;
	} else if (file.includes('-')) {
		return file.split('-')[0];
	}

	return null;
}

const getTitle = (liedje: LiedjeEntity) => {
	const file = liedje.file.split('.').slice(0, -1).join('.');
	const id3 = liedje?.title ?? '';

	if (id3) {
		return id3;
	} else if (file.includes('-')) {
		return file.split('-')[1];
	}

	return null;
}

const tryJsonParse = (input: any) => {
	try {
		return JSON.parse(input);
	} catch (ex) {
		return null;
	}
}

export { checkHttpStatus };
export { httpCheckParse };
export { checkNotEmpty };
export { getArtist };
export { getTitle };
export { tryJsonParse };