import ModalDialog from "./Modal";
import React, {useContext, useState} from "react";
import {Button, Form, FormControl, Spinner} from "react-bootstrap";
import AppContext from "./AppContext";

const SetLogoModal = () => {
	const { settings, setModal, env } = useContext(AppContext);

	const [url, setUrl] = useState<string>(settings?.logo?.url ?? '');
	const [loading, setLoading] = useState<boolean>(false);

	if (loading) {
		return <Spinner animation="border" />;
	}

	return <ModalDialog title={"Kies een logo"} closeable={true}>
		<Form onSubmit={e => {
			e.preventDefault();

			setLoading(true);
			fetch(`${env.BACKEND_ENDPOINT}/settings/logo`, {
				method: 'PUT',
				body: JSON.stringify({ url })
			})
				.then(() => {
					setModal(null);
					window.location.reload()
				})
				.catch(window.alert)
				.then(() => setLoading(false));
		}}>
			<label style={{
					width: '100%'
				}}>URL van gewenste afbeelding:
				<FormControl
					placeholder="https://....png"
					type={url}
					required
					value={url} onChange={e => setUrl(e?.target?.value)}
				/>
			</label>

			{url && <div>
				<h2>Voorbeeld</h2>
				<div className="logo-container">
					<img className="logo" src={url} alt="" />
				</div>
			</div>}

			{url
				? <Button type="submit">Logo instellen</Button>
				: <Button type="submit" variant="danger">Logo verwijderen</Button>}
		</Form>
	</ModalDialog>;
};

export default SetLogoModal;