import React, {useContext} from 'react';
import AppContext from "./AppContext";
import { checkNotEmpty } from './utils';

const EditableText = ({ textKey, children } : { textKey: string, children: any }) => {
	const { admin, getText, refreshSettings, env, setLoading } = useContext(AppContext);

	const currentText = getText(textKey, children);

	if (!admin) {
		return <span>{currentText}</span>
	}

	return <span>{currentText} <span role="img" style={{
		cursor: 'pointer'
	}} onClick={() => {
		const newText: string | null = window.prompt(`Tekst voor "${children}":`, currentText);
		if (!newText) {
			return;
		}

		setLoading(true);
		fetch(`${env.BACKEND_ENDPOINT}/settings/text/${checkNotEmpty(textKey)}`, {
			method: 'PUT',
			body: JSON.stringify({ text: newText })
		})
			.then(() => refreshSettings())
			.catch(window.alert)
			.then(() => setLoading(false));
	}}>✏️</span></span>;
};

export default EditableText;