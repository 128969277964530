import {Modal} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "./AppContext";

const ModalDialog = ({ title, children, closeable = true } : { title: string, children: any, closeable: boolean}) => {
	const { setModal } = useContext(AppContext);

	return <Modal
		centered
		show={true}
		size="lg"
		onHide={() => setModal(null)}>
		<Modal.Header closeButton={closeable}>
			{title &&
				<Modal.Title>{title}</Modal.Title>}
		</Modal.Header>

		<Modal.Body>
			{children}
		</Modal.Body>
	</Modal>;
};

export default ModalDialog;