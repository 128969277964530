import React, {useState, useEffect, useRef, ChangeEvent, useCallback, useMemo} from 'react';
import './App.scss';
import {getArtist, getTitle, httpCheckParse, tryJsonParse} from './utils';
import Liedje from './Liedje';
import LiedjeEntity from './entity/LiedjeEntity';
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, ListGroup, FormControl, Spinner, Button, Alert} from 'react-bootstrap';
import VerzoekjeEntity from './entity/VerzoekjeEntity';
import SettingsEntity from "./entity/SettingsEntity";
import useInterval from 'use-interval';
import Verzoekje from './Verzoekje';
import AppContext from "./AppContext";
import SetLogoModal from "./SetLogoModal";
import Overlay from "./Overlay";
import EnvEntity from "./entity/EnvEntity";
import EditableText from "./EditableText";
import useLocalStorage from "react-hook-uselocalstorage";
import {v4 as uuid} from 'uuid';
import DedupedVerzoekjeEntity from "./entity/DedupedVerzoekjeEntity";

const App = ({ env } : { env: EnvEntity }) => {
	const [user, _setUser] = useLocalStorage('user');
	const [query, setQuery] = useState<string>('');
	const [liedjes, setLiedjes] = useState<LiedjeEntity[]>([]);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [modal, setModal] = useState<any | null>(null);
	const [verzoekjes, setVerzoekjes] = useState<VerzoekjeEntity[]>([]);
	const [initialVerzoekjesLoaded, setInitialVerzoekjesLoaded] = useState<boolean>(false);
	const [settings, setSettings] = useState<SettingsEntity>({} as SettingsEntity);
	const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
	const [requestsMadeFromDevice, _setRequestsMadeFromDevice] = useLocalStorage('requests_made');
	const abortControllerRef = useRef<AbortController | null>();

	if (!user) {
		_setUser(uuid());
	}
	if (!requestsMadeFromDevice) {
		_setRequestsMadeFromDevice(JSON.stringify([]));
	}

	const getText = (key: string, fallback : string) => settings?.text?.[key] || fallback;

	const admin = window.location.hash?.includes('admin');
	const appendToDbFileInputField = useRef() as React.MutableRefObject<HTMLInputElement>;
	const appendToDbFileUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
		try {
			if (!admin) {
				return;
			}

			const files = e.target?.files ?? [];

			if (files.length < 1) {
				return;
			} else if (files.length > 1) {
				throw new Error(`Meerdere bestanden geselecteerd.`);
			}

			setLoading(true);

			const fileReader = new FileReader();
			fileReader.readAsText(files[0], 'UTF-8');
			fileReader.onload = e => {
				const fileData = e?.target?.result;
				if (!fileData) {
					throw new Error('Database is leeg, of lezen is mislukt.');
				} else if (!window.confirm('De geselecteerde database zal worden ingeladen en worden toegevoegd aan de huidige database. Weet je het zeker?')) {
					setLoading(false);
					return;
				}

				fetch(`${env.BACKEND_ENDPOINT}/liedje`, {
					method: 'PATCH',
					body: fileData
				})
					.then(httpCheckParse)
					.then(({ n }) => window.alert(`${n} liedjes aan database toegevoegd.`))
					.then(() => window.location.reload())
					.catch(alert)
					.then(() => setLoading(false));
			};
		} catch (ex) {
			window.alert(ex);
			setLoading(false);
		}
	}

	const deleteDbClickHandler = (e: any) => {
		e.preventDefault();

		if (!admin) {
			return;
		} else if (!window.confirm('De hele muziekdatabase en alle verzoekjes zullen worden gewist. Weet je het zeker?')) {
			return;
		}

		setLoading(true);
		fetch(`${env.BACKEND_ENDPOINT}/liedje`, {
			method: 'DELETE'
		}).then(httpCheckParse)
		.catch(window.alert)
		.then(() => setLoading(false));
	}

	const refreshSettings = useCallback(() => fetch(`${env.BACKEND_ENDPOINT}/settings`)
		.then(httpCheckParse)
		.then(data => {
			setSettings(data);
			setSettingsLoaded(true);
		})
		.catch(console.error),
		[env.BACKEND_ENDPOINT, setSettings, setSettingsLoaded]);

	useEffect(() => {
		if (settingsLoaded) {
			return;
		} else if (settings && Object.keys(settings).length > 0) {
			return;
		}

		refreshSettings();
	}, [refreshSettings, settings, settingsLoaded]);


	useEffect(() => {
		if (abortControllerRef.current) {
			abortControllerRef.current?.abort();
		}

		if (!query) {
			setLiedjes([]);
			return;
		}

		abortControllerRef.current = new AbortController();

		setSearchLoading(true);
		fetch(`${env.BACKEND_ENDPOINT}/zoek/${encodeURIComponent(query)}`, {
			signal: abortControllerRef.current?.signal
		})
			.then(httpCheckParse)
			.then(setLiedjes)
			.catch(err => setError(err.message))
			.then(() => setSearchLoading(false));
	}, [query, env.BACKEND_ENDPOINT]);

	const notifyNewVerzoekjes = useCallback((newVerzoekjes: VerzoekjeEntity[]) => {
		try {
			if (!admin) {
				return newVerzoekjes;
			} else if (Notification.permission !== 'granted') {
				return newVerzoekjes;
			} else if (!initialVerzoekjesLoaded) {
				return newVerzoekjes;
			}

			const currentVerzoekjesKeys: string[] = verzoekjes.map(v => v.key);
			(newVerzoekjes || []).forEach(newVerzoekje => {
				if (!currentVerzoekjesKeys.includes(newVerzoekje.key)) {
					// Nieuw verzoekje!
					const liedjeStr = `${getArtist(newVerzoekje.liedje)} - ${getTitle(newVerzoekje.liedje)}`;
					new Notification('Nieuw verzoekje', {
						body: liedjeStr
					});
				}
			});
		} catch (ex) {
			console.error(ex);
		}

		return newVerzoekjes;
	}, [verzoekjes, admin, initialVerzoekjesLoaded]);

	const refreshVerzoekjes = () => fetch(`${env.BACKEND_ENDPOINT}/verzoek`)
		.then(httpCheckParse)
		.then(notifyNewVerzoekjes)
		.then(setVerzoekjes)
		.then(() => setInitialVerzoekjesLoaded(true))
		.catch(err => setError(err.message));

	useInterval(refreshVerzoekjes, 5000, true);

	const adminSetLogo = (e: React.ChangeEvent<any>) => {
		if (!admin) {
			return;
		}
		e.preventDefault();

		setModal(<SetLogoModal />);
	}

	const onVerzoekjeHandler = useCallback(verzoekjeKey => {
		try {
			// [{"key":"72483ea7-0deb-4f52-b5dc-db852cd47eb8","timestamp":"2021-10-05T12:16:28.128Z"},{"key":"2d59d045-5392-42ca-97b3-683cfe28c8e4","timestamp":"2021-10-05T12:16:31.478Z"}]
			_setRequestsMadeFromDevice(JSON.stringify([
				...(tryJsonParse(requestsMadeFromDevice) || []),
				{
					key: verzoekjeKey,
					timestamp: new Date()
				}
			]));
		} catch (ex) {
			console.error(ex);
		}
	}, [requestsMadeFromDevice, _setRequestsMadeFromDevice]);

	const timeToWaitUntilNextRequest = useCallback(() => {
		const now: Date = new Date();
		const TWENTY_MINUTES_IN_MS = 1000 * 60 * 20;
		const verzoekjesTimestampsInLast20Minutes = (tryJsonParse(requestsMadeFromDevice) || [])
			.map((x: any) => x?.timestamp)
			.filter((x: string | undefined) => !!x)
			.map((x: string) => new Date(x))
			.filter((requestTimestamp: Date) => now.getTime() - requestTimestamp.getTime() < TWENTY_MINUTES_IN_MS)
			.map((x: Date) => x.getTime());
		if (verzoekjesTimestampsInLast20Minutes.length < 2) {
			return 0;
		}

		const latestVerzoekjeTimestamp: number = Math.max(...verzoekjesTimestampsInLast20Minutes);
		const timeToWaitMs: number = TWENTY_MINUTES_IN_MS - (now.getTime() - latestVerzoekjeTimestamp);

		return timeToWaitMs;
	}, [requestsMadeFromDevice]);

	const verzoekjesDeduped: DedupedVerzoekjeEntity[] = useMemo(() => {
		const deduped: DedupedVerzoekjeEntity[] = [];
		for (const verzoekje of verzoekjes) {
			const existingVerzoekje : DedupedVerzoekjeEntity | undefined = deduped.find((iVerzoekje: VerzoekjeEntity) => iVerzoekje?.liedje?.key === verzoekje?.liedje?.key);
			if (existingVerzoekje) {
				existingVerzoekje.users.push(verzoekje.user);
				continue;
			}

			deduped.push({
				...verzoekje,
				users: [verzoekje?.user].filter(x => !!x)
			} as DedupedVerzoekjeEntity);
		}

		return deduped;
	}, [verzoekjes]);

	if (loading) {
		return <Container>
			<Row>
				<Col>
					<div style={{
						width: '100%',
						textAlign: 'center',
						padding: 20
					}}>
						<Spinner animation="border" style={{
							display: 'inline-block',
							width: 200,
							height: 200
						}} />
					</div>
				</Col>
			</Row>
		</Container>;
	}

	return (
		<AppContext.Provider value={{
			loading,
			setLoading,
			verzoekjes,
			admin,
			modal,
			setModal,
			settings,
			refreshSettings,
			refreshVerzoekjes,
			env,
			getText,
			user
		}}>
			<Container>
				{admin && <Row>
					<Col>
						<Button variant="danger" onClick={deleteDbClickHandler}>Muziekdatabase verwijderen</Button>
						<form style={{
							display: 'inline'
						}}>
							<input type="file" hidden ref={appendToDbFileInputField} onChange={appendToDbFileUploadHandler} />
							<Button variant="warning" onClick={e => {
								e.preventDefault();

								appendToDbFileInputField.current.click();
							}}>Muziekdatabase inladen</Button>
						</form>
						{Notification.permission === 'default' &&
							<Button
								onClick={() => Notification.requestPermission()
									.then(permission => {
										if (permission === 'granted') {
											new Notification('Notificaties ingeschakeld.', {
												body: 'Je krijgt nu meldingen over nieuwe verzoekjes, zo lang de pagina open staat.'
											});
										}
									})}
							>Notificaties inschakelen</Button>
						}
					</Col>
				</Row>}
				<Row>
					<Col className="logo-container">
						{settings?.logo?.url &&
							<img className="logo" src={settings.logo.url} alt="" onClick={admin ? adminSetLogo : () => void 0}/>}
						{!settings?.logo?.url && admin &&
							<Button onClick={adminSetLogo}>Logo instellen</Button>}
					</Col>
				</Row>
				<Row>
					<Col>
						<h1><EditableText textKey="top_titel">Waar is da feestje?</EditableText></h1>
						<EditableText textKey="top_alinea">Hier is da feestje!</EditableText>
					</Col>
				</Row>
				<Row>
					<Col>
						<h2><EditableText textKey="titel_vraag_liedje_aan">Vraag een liedje aan</EditableText></h2>
						<FormControl
							placeholder="Zoek naar een liedje..."
							aria-label="Zoek naar een liedje"
							value={query} onChange={e => setQuery(e?.target?.value)}
						/>
					</Col>
				</Row>
				{timeToWaitUntilNextRequest() > 0 && <Row>
					<Col>
						<Alert variant="info"><EditableText textKey="max_2_verzoekjes_per_20_min">Je mag maar 2 nummers aanvragen elke 20 minuten. Wacht nog even voor je volgende verzoeknummer.</EditableText></Alert>
					</Col>
				</Row>}
				<Row>
					<Col>
						<ListGroup style={{
							width: '100%'
						}}>
							{liedjes.map(x => <Liedje
								key={x.key}
								onVerzoekje={onVerzoekjeHandler}
								verzoekjeAllowed={timeToWaitUntilNextRequest() === 0}
								liedje={x} />)}
						</ListGroup>
					</Col>
				</Row>
				<Row>
					<Col>
					{searchLoading &&
						<div style={{
							width: '100%',
							textAlign: 'center',
							padding: 4
						}}>
							<Spinner animation="border" style={{
								display: 'inline-block'
							}} />
						</div>}
					</Col>
				</Row>
				<Row>
					<Col>
						<h2><EditableText textKey="titel_verzoekjes">Verzoekjes</EditableText></h2>
						{verzoekjes.length < 1 ?
							<p style={{
								display: 'block'
							}}><EditableText textKey="momenteel_geen_verzoekjes">Er zijn momenteel nog geen verzoekjes. Zoek in de balk hierboven naar jouw favoriete nummer om het aan te vragen!</EditableText></p> :
							<ListGroup style={{
								width: '100%'
								}}>
									{verzoekjesDeduped.map((x: DedupedVerzoekjeEntity) => <Verzoekje
										key={x.key}
										users={x.users}
										verzoekje={x} />)}
							</ListGroup>}
					</Col>
				</Row>
			</Container>

			{modal && <Overlay>
				{modal}
			</Overlay>}
		</AppContext.Provider>
	);
}

export default App;
