import React, {useCallback, useContext, useEffect, useState} from 'react';
import {httpCheckParse} from './utils';
import AppContext from "./AppContext";

const Avatar = ({ userKey }: { userKey: string }) => {
	const [avatar, setAvatar] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const {
		env,
		admin
	} = useContext(AppContext);

	const loadAvatar = useCallback(() => fetch(`${env.BACKEND_ENDPOINT}/avatar/${userKey}`)
		.then(httpCheckParse)
		.then(({ avatar }) => avatar)
		.then(setAvatar),
		[setAvatar, env.BACKEND_ENDPOINT, userKey]);
	useEffect(() => {
		if (!admin || avatar || loading) {
			return;
		}

		setLoading(true);
		loadAvatar()
			.catch(console.error)
			.then(() => setLoading(false));
	}, [avatar, loading, setAvatar, setLoading, admin, loadAvatar]);

	if (!avatar) {
		return <span />;
	}

	return <span className="avatar">{avatar}</span>;
};

export default Avatar;